import type { GridValidRowModel } from '@mui/x-data-grid-pro';
import type { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import dayjs from 'dayjs';
import type { MutableRefObject } from 'react';

import { dayjsFormatVariant } from 'constant/dateFormat';

/**
 *
 * @param data - строки таблицы
 * @param apiRef - apiRef
 * @returns обновленный data, разбитый по месяц_год
 */
export const updateData = (data: GridValidRowModel[], apiRef: MutableRefObject<GridApiPro>) => {
	const newData: Record<string, GridValidRowModel[]> = {};

	const columnsData = apiRef.current
		.getVisibleColumns()
		.filter(column => column.type === 'date')
		.map(column => column.field);

	data.forEach(el => {
		const dataItem = {
			...el[1],
		};
		columnsData.forEach(key => {
			dataItem[key] = dayjs(dataItem[key]).format(dayjsFormatVariant);
		});
		if (!newData.all) {
			newData.all = [];
		}
		newData.all.push(dataItem);
	});

	data.forEach(el => {
		const dataItem = { ...el[1] };

		columnsData.forEach(key => {
			if (dataItem[key]) {
				dataItem[key] = dayjs(dataItem[key]).format(dayjsFormatVariant);
			}
		});

		const monthYear = dayjs(dataItem.dateOfTheNextVerification, dayjsFormatVariant).format(
			'MMMM_YYYY'
		);

		if (!newData[monthYear]) {
			newData[monthYear] = [];
		}
		newData[monthYear].push(dataItem);
	});

	Object.keys(newData).forEach(label => {
		if (label === 'all') return;
		newData[label].sort((a, b) =>
			dayjs(a.dateOfTheNextVerification, dayjsFormatVariant).isBefore(
				dayjs(b.dateOfTheNextVerification, dayjsFormatVariant)
			)
				? -1
				: 1
		);
	});

	const sortedKeys = Object.keys(newData).sort((a, b) =>
		dayjs(a, 'MMMM_YYYY').isBefore(dayjs(b, 'MMMM_YYYY')) ? -1 : 1
	);

	const sortedData: Record<string, GridValidRowModel[]> = {};
	sortedKeys.forEach(key => {
		sortedData[key] = newData[key];
	});

	return sortedData;
};
